import { HStack, Text } from '@chakra-ui/react'
import { FormatNumberOptions, useIntl } from 'react-intl'

interface CouponCodeProps {
  couponCodeName: string
  discount: number
  formatNumberOptions: FormatNumberOptions
  hideDiscount?: boolean
}

export const CouponCodeText: React.FC<CouponCodeProps> = ({
  couponCodeName,
  discount,
  hideDiscount,
  formatNumberOptions,
}) => {
  const intl = useIntl()

  return (
    <HStack color="fuchsia.500" justifyContent="space-between" width="full">
      <Text as="span" size="md" title={couponCodeName} textStyle="ellipsis">
        {couponCodeName}
      </Text>
      {!hideDiscount && (
        <Text as="span" fontWeight="bold" size="md" whiteSpace="nowrap">
          - {intl.formatNumber(discount / 100, formatNumberOptions)}
        </Text>
      )}
    </HStack>
  )
}
