import { Button, ButtonProps, Text } from '@chakra-ui/react'
import { Lock } from '@modules/ui/icons/Lock'

export default function ActionButton({
  onClick,
  isDisabled,
  isLoading,
  children,
  variant,
  width,
  id,
}: ButtonProps) {
  return (
    <Button
      id={id}
      leftIcon={<Lock />}
      marginTop={0}
      colorScheme="teal"
      size="lg"
      onClick={onClick}
      disabled={isDisabled}
      isLoading={isLoading}
      variant={variant ?? 'solid'}
      width={width ?? '100%'}
    >
      <span>{children}</span>
    </Button>
  )
}
